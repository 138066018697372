import "./fonts.css";

function App() {
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(23,28,30)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "denkone",
      }}
    >
      <img
        style={{
          width: 100,
          height: 100,
          borderWidth: 2,
          borderRadius: 5,
          borderColor: "black",
          overflow: "hidden",
        }}
        src={"logo.jpg"}
      />
      <div
        style={{
          paddingTop: 20,
          fontSize: 20,
          color: "rgb(250,240,230)",
        }}
      >
        Bzzzzzzz. COMING SOON.
      </div>
    </div>
  );
}

export default App;
